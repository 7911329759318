<template>
  <join-to-module-component />
</template>

<script>
import JoinToModuleComponent from "@/components/admin/JoinToModuleComponent";

export default {
  name: "JoinToBHCModule",
  title: "Gestión Unete a Turismo BHT",
  components: { JoinToModuleComponent },
};
</script>

<style scoped></style>
